var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "기본정보" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          Boolean(_vm.check.chmDangerRegularCheckId) &&
                          !_vm.disabled &&
                          _vm.editable,
                        expression:
                          "Boolean(check.chmDangerRegularCheckId) && !disabled && editable",
                      },
                    ],
                    attrs: {
                      url: _vm.completeUrl,
                      isSubmit: _vm.isComplete,
                      param: _vm.check,
                      mappingType: "PUT",
                      label: "완료",
                      icon: "check",
                    },
                    on: {
                      beforeAction: _vm.completeRegularCheck,
                      btnCallback: _vm.completeCallback,
                    },
                  }),
                  Boolean(_vm.check.chmDangerRegularCheckId) &&
                  !_vm.disabled &&
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "삭제", icon: "delete_forever" },
                        on: { btnClicked: _vm.remove },
                      })
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.check,
                          mappingType: _vm.saveType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveRegularCheck,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-danger-area", {
                  ref: "dangerArea",
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "위험물 저장소",
                    name: "chmDangerMstId",
                  },
                  on: { detailInfo: _vm.detailInfo },
                  model: {
                    value: _vm.check.chmDangerMstId,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "chmDangerMstId", $$v)
                    },
                    expression: "check.chmDangerMstId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    type: "edit",
                    codeGroupCd: "CHM_DANGER_TYPE_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "chmDangerTypeCd",
                    label: "구분",
                  },
                  model: {
                    value: _vm.check.chmDangerTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "chmDangerTypeCd", $$v)
                    },
                    expression: "check.chmDangerTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-multi-field", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    data: _vm.check,
                    disabled: _vm.disabled,
                    label: "점검자",
                    type: "user",
                    name: "chmDangerInspectorUserId",
                  },
                  model: {
                    value: _vm.check.chmDangerInspectorUserId,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "chmDangerInspectorUserId", $$v)
                    },
                    expression: "check.chmDangerInspectorUserId",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    range: true,
                    label: "점검기간",
                    name: "period",
                  },
                  model: {
                    value: _vm.check.period,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "period", $$v)
                    },
                    expression: "check.period",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    label: "점검명",
                    disabled: _vm.disabled,
                    name: "chmDangerCheckName",
                  },
                  model: {
                    value: _vm.check.chmDangerCheckName,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "chmDangerCheckName", $$v)
                    },
                    expression: "check.chmDangerCheckName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: {
                    required: "",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.check.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.check, "plantCd", $$v)
                    },
                    expression: "check.plantCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-table", {
        ref: "grid",
        attrs: {
          title: "정기점검항목 목록",
          columns: _vm.grid.columns,
          gridHeight: _vm.gridHeight,
          data: _vm.check.checkResults,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          noDataLabel: "점검항목을 추가하세요.",
          hideBottom: true,
          editable: _vm.editable && !_vm.disabled,
          rowKey: "chmDangerRegularCheckResultId",
          selection: "multiple",
        },
        scopedSlots: _vm._u([
          {
            key: "customArea",
            fn: function ({ props, col }) {
              return [
                col.name === "customCol"
                  ? [
                      _c(_vm.imprComponent, {
                        tag: "component",
                        attrs: {
                          col: col,
                          props: props,
                          inputEditable: _vm.editable && !_vm.disabled,
                          isImmShow: true,
                          requestContentsCols: _vm.requestContentsCols,
                          tableKey: "chmDangerRegularCheckResultId",
                          ibmTaskTypeCd: "ITT0000205",
                          ibmTaskUnderTypeCd: "ITTU000235",
                        },
                        on: { imprChange: _vm.imprChange },
                      }),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }