<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="Boolean(check.chmDangerRegularCheckId) && !disabled && editable"
            :url="completeUrl"
            :isSubmit="isComplete"
            :param="check"
            mappingType="PUT"
            label="완료"
            icon="check"
            @beforeAction="completeRegularCheck"
            @btnCallback="completeCallback" 
          />
          <c-btn
            v-if="Boolean(check.chmDangerRegularCheckId) && !disabled && editable" 
            label="삭제" icon="delete_forever" @btnClicked="remove" />
          <c-btn
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="check"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveRegularCheck"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-danger-area
            required
            ref="dangerArea"
            :editable="editable"
            :disabled="disabled"
            label="위험물 저장소"
            name="chmDangerMstId"
            v-model="check.chmDangerMstId"
            @detailInfo="detailInfo"
          ></c-danger-area>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :disabled="true"
            type="edit"
            codeGroupCd="CHM_DANGER_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="chmDangerTypeCd"
            label="구분"
            v-model="check.chmDangerTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-field
            required
            :editable="editable"
            :data="check"
            :disabled="disabled"
            label="점검자"
            type="user"
            name="chmDangerInspectorUserId"
            v-model="check.chmDangerInspectorUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            :required="true"
            :editable="editable"
            :disabled="disabled"
            :range="true"
            label="점검기간"
            name="period"
            v-model="check.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-text
            required
            :editable="editable"
            label="점검명"
            :disabled="disabled"
            name="chmDangerCheckName"
            v-model="check.chmDangerCheckName">
          </c-text>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            required
            :editable="editable"
            :disabled="disabled"
            type="edit" 
            name="plantCd" 
            v-model="check.plantCd" />
        </div>
      </template>
    </c-card>
    <c-table
      ref="grid"
      title="정기점검항목 목록"
      :columns="grid.columns"
      :gridHeight="gridHeight"
      :data="check.checkResults"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      noDataLabel="점검항목을 추가하세요."
      :hideBottom="true"
      :editable="editable && !disabled"
      rowKey="chmDangerRegularCheckResultId"
      selection="multiple"
    >
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customCol'">
          <component
            :is="imprComponent"
            :col="col"
            :props="props"
            :inputEditable="editable && !disabled"
            :isImmShow="true"
            :requestContentsCols="requestContentsCols"
            tableKey="chmDangerRegularCheckResultId"
            ibmTaskTypeCd="ITT0000205"
            ibmTaskUnderTypeCd="ITTU000235"
            @imprChange="imprChange"
          />
        </template>
      </template>
      <!-- <template slot="table-button">
        <q-btn-group outline>
          <c-btn 
            v-if="editable" 
            :showLoading="false" 
            label="추가" 
            icon="add" 
            @btnClicked="addResult" />
          <c-btn 
            v-if="editable && check.checkResults && check.checkResults.length > 0" 
            :showLoading="false" 
            label="제외" 
            icon="remove" 
            @btnClicked="removeResult" />
        </q-btn-group>
      </template> -->
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'dangerMaintenanceDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmDangerRegularCheckId: '',
        chmStatusDangerRegularCheckCd: ''
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      check: {
        chmDangerRegularCheckId: '',
        chmDangerMstId: '',
        chmDangerMstTypeId: '',
        plantCd: '',
        chmDangerTypeCd: null,
        chmDangerLocation: '',
        chmDangerCheckName: '',
        chmDangerPermitNo: '',
        chmDangerUserId: '',
        chmDangerManageUserId: '',
        chmDangerSafetyUserId: '',
        chmDangerInspectorUserId: '',
        chmStatusDangerRegularCheckCd: '',
        chmDangerCount: '',
        installPermitDate: '',
        chmDangerHandlingOverview: '',
        chmDangerStartDate: '',
        chmDangerEndDate: '',
        period: [],
        checkResults: [],
        deleteCheckResults: [],
        types: [],
        
      },
      grid: {
        columns: [
          
        ],
        height: '300px',
        data: [],
      },
      editable: true,
      isSave: false,
      isComplete: false,
      saveType: 'POST',
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    gridHeight() {
      let height = this.contentHeight - 250;
      if (height < 600) {
        height = 600;
      }
      return String(height) + 'px';
    },
    itemHeight() {
      let height = this.contentHeight - 250;
      if (height < 300) {
        height = 100;
      }
      return String(height) + 'px';
    },
    requestContentsCols() {
      return ['dangerCheckItem'];
    },
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
    },
    disabled() {
      return this.check.chmStatusDangerRegularCheckCd === 'CSDRCC0002';
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.chm.danger.regularChecks.get.url
      this.saveUrl = transactionConfig.chm.danger.regularCheck.update.url;
      this.completeUrl = transactionConfig.chm.danger.regularCheck.complete.url;
      this.deleteUrl = transactionConfig.chm.danger.regularCheck.delete.url;
      // code setting
      // list setting
      this.setHeader()
      this.getDetail()
    },
    getDetail() {
      if (this.popupParam.chmDangerRegularCheckId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.chmDangerRegularCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.check = _result.data;
          this.check.period = [_result.data.chmDangerStartDate, _result.data.chmDangerEndDate];
        },);
      }
    },
    setHeader() {
      this.$comm.getComboItems('CHM_DANGER_REGULAR_CHECK_RESULT_CD').then(_result => {
        this.grid.columns = [
         {
            name: 'dangerCheckItem',
            field: 'dangerCheckItem',
            label: '점검항목',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'dangerCheckContent',
            field: 'dangerCheckContent',
            label: '점검내용',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
          {
            name: 'dangerCheckMethod',
            field: 'dangerCheckMethod',
            label: '점검방법',
            style: 'width:100px',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'dangerCheckResult',
            field: 'dangerCheckResult',
            label: '점검결과',
            setHeader: true,
            align: 'center',
            style: 'width:100px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },
          {
            setHeader: true,
            name: 'dangerCheckRemark',
            field: 'dangerCheckRemark',
            // 비고
            label: '비고',
            align: 'left',
            type: 'textarea',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'customCol',
            field: 'customCol',
            label: '개선/진행상태',
            align: 'center',
            type: 'custom',
            style: 'width:100px',
            sortable: false
          },
        ]
      });
    },
    // addResult() { // 현재는 비활성화 시켰지만 추가 버튼이 있을 경우 목록에서 추가하는 부분
    //   this.popupOptions.title = '위험물 정기점검 목록';
    //   this.popupOptions.target = () => import(`${'@/pages/chm/base/dangerStorageCheckItemPop.vue'}`);
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closePopupDangerCheckItem;
    // },
    // closePopupDangerCheckItem(data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if (data && data.length > 0) {
    //     this.$_.forEach(data, item => {
    //       this.check.checkResults.push({
    //         chmDangerRegularCheckId: this.popupParam.chmDangerRegularCheckId,
    //         chmDangerStorageCheckItemId: item.chmDangerStorageCheckItemId,
    //         chmCheckResultId: uid(),
    //         chmDangerTypeName: item.chmDangerTypeName,
    //         dangerCheckItem: item.dangerCheckItem,  // 항목
    //         dangerCheckContent: item.dangerCheckContent,  // 내용
    //         dangerCheckMethod: item.dangerCheckMethod,  // 결과
    //         regUserId: this.$store.getters.user.userId,
    //         editFlag: 'C'
    //       })
    //     })
    //   }
    // },
    detailInfo(data) {
      if(!this.popupParam.chmDangerRegularCheckId){
        data = this.$_.extend(data, {
          chmDangerLocation: data.chmDangerArea,
          chmDangerPermitNo: data.permitNo,
          chmDangerManageUserId: data.chmDangerManageUserId,
          chmDangerUserId: data.chmDangerUserId
        })
        data.checkitems = this.$_.map(data.typeList, item =>{
          return this.$_.extend(item, {
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
        })
        data.checkResults = this.$_.map(data.itemList, item =>{
          return this.$_.extend(item, {
            dangerCheckResult: 'CDRCRC0001',
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
        })
        this.$_.extend(this.check, data)
        // this.check.chmDangerTypeCd = data.chmDangerTypeCd
        // this.check.chmDangerCount = data.chmDangerCount
        // this.check.chmDangerLocation = data.chmDangerArea
        // this.check.installPermitDate = data.installPermitDate
        // this.check.chmDangerPermitNo = data.permitNo
        // this.check.checkResults = data.items
        // this.check.types = data.types
        // this.check.chmDangerManageUserId = data.chmDangerManageUserId
        // this.check.chmDangerUserId = data.chmDangerUserId
      } else {
        data.checkitems = this.$_.map(data.typeList, item =>{
          return this.$_.extend(item, {
            regUserId: this.$store.getters.user.userId,
            editFlag: 'C'
          })
        })
        this.$_.extend(this.check, data)
      }
    },
    // removeResult() { // 현재는 비활성화 시켰지만 제외 버튼이 있을 경우 목록에서 제외하는 부분
    //   let selectData = this.$refs['grid'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: '안내', // 안내
    //       message: '선택된 항목이 없습니다.', // 선택된 항목이 없습니다.
    //       type: 'warning', // success / info / warning / error
    //     });
    //   } else {
    //     this.$_.forEach(selectData, item => {
    //       if (!this.check.deleteCheckResults) {
    //         this.check.deleteCheckResults = []
    //       }
    //       if (this.$_.findIndex(this.check.deleteCheckResults, { chmDangerMstTypeId: item.chmDangerMstTypeId }) === -1
    //         && item.editFlag !== 'C') {
    //           this.check.deleteCheckResults.push(item)
    //       }
    //       this.check.checkResults = this.$_.reject(this.check.checkResults, item)
    //     })
    //     this.$refs['grid'].$refs['compo-table'].clearSelection();
    //   }
    // },
    saveRegularCheck() {
      if (this.popupParam.chmDangerRegularCheckId) {
        this.saveUrl = transactionConfig.chm.danger.regularCheck.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.chm.danger.regularCheck.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result && this.$comm.validTable(this.grid.columns, this.check.checkResults)) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (this.check.period && this.check.period.length > 0) {
                this.check.chmDangerStartDate = this.check.period[0]
                this.check.chmDangerEndDate = this.check.period[1]
              }
              this.check.regUserId = this.$store.getters.user.userId
              this.check.chgUserId = this.$store.getters.user.userId
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if(!this.popupParam.chmDangerRegularCheckId) {
        this.$emit('changeStatus')
        this.popupParam.chmDangerRegularCheckId = result.data;
      }
      this.getDetail();
    },
    completeRegularCheck() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.check.regUserId = this.$store.getters.user.userId
              this.check.chgUserId = this.$store.getters.user.userId
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('changeComplete')
      this.getDetail();
    },
    /* eslint-disable no-unused-vars */
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.chmDangerRegularCheckId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    imprChange() {
      this.$refs['grid'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
